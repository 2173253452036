<template>
  <section class="hero d-flex align-items-center section-bg">
    <div class="container-fluid px-0">
      <div class="row justify-content-between gy-3 mx-0">
        <div class="col-lg-4 milkar-order-2 card banner-section">
          <a @click="createCauseClick" id="" class="btn btn-primary w-100 text-center mb-2 mt-0"><i
              class="fa-solid fa-plus pr-1"></i> Create
            Cause</a>
          <tabs @changed="tabMapChanged">
            <tab name="Recent Causes" id="causes">
              <div ref="slider" class="keen-slider" style="height: 300px">
                <div v-if="loadingAllEvents" class="w-100 row mx-0 row-gap-2">
                  <div class="d-flex align-items-start causesection-listing-card p-2 gap-2 w-100">
                    <Skeleton size="7rem"></Skeleton>
                    <div class="d-flex flex-column align-items-start w-100">
                      <Skeleton width="100%" class="mb-2"></Skeleton>
                      <Skeleton width="100%" class="mb-2"></Skeleton>
                      <Skeleton width="50%" class="mb-2"></Skeleton>
                      <Skeleton width="30%" height="2rem" class="mb-2"></Skeleton>
                    </div>
                  </div>
                  <div class="d-flex align-items-start causesection-listing-card p-2 gap-2 w-100">
                    <Skeleton size="7rem"></Skeleton>
                    <div class="d-flex flex-column align-items-start w-100">
                      <Skeleton width="100%" class="mb-2"></Skeleton>
                      <Skeleton width="100%" class="mb-2"></Skeleton>
                      <Skeleton width="50%" class="mb-2"></Skeleton>
                      <Skeleton width="30%" height="2rem" class="mb-2"></Skeleton>
                    </div>
                  </div>
                  <div class="d-flex align-items-start causesection-listing-card p-2 gap-2 w-100">
                    <Skeleton size="7rem"></Skeleton>
                    <div class="d-flex flex-column align-items-start w-100">
                      <Skeleton width="100%" class="mb-2"></Skeleton>
                      <Skeleton width="100%" class="mb-2"></Skeleton>
                      <Skeleton width="50%" class="mb-2"></Skeleton>
                      <Skeleton width="30%" height="2rem" class="mb-2"></Skeleton>
                    </div>
                  </div>
                </div>
                <div v-else v-for="(event, index) in allEvents" :key="index" class="keen-slider__slide"
                  :class="'number-slide' + (index + 1)">
                  <div class="d-flex align-items-center causesection-listing-card">
                    <div class="pl-2 pr-md-0 ">
                      <a :href="baseUrl + '/activities/' + event.slug">
                        <img v-if="event.thumbnail_picture" :src="this.imageurl + '/' + event.thumbnail_picture"
                          class="map-img-block img-fluid">
                        <img v-else src="@/assets/img/org_placeholder.png" class="map-img-block img-fluid">
                      </a>
                    </div>
                    <div class="pl-md-0 pr-md-0 text-truncate mb-0 causes-detail-right">
                      <div class="activity-info-text text-md-left px-3 text-truncate">
                        <a :href="baseUrl + '/activities/' + event.slug" target="_blank">
                          <h4 class="mb-1 mt-1 mt-md-0 text-truncate-1 text-capitalize">
                            {{ event.name }}
                          </h4>
                        </a>
                        <p v-if="event.OwenerDetail[0]" class="p-0 mb-1 text-truncate-1 text-capitalize"
                          v-tooltip.bottom="event.event_type.name && event.OwenerDetail[0].name">{{
            event.event_type.name }} by
                          <a href="" class="text-primary text-capitalize">{{
            event.OwenerDetail[0].name }}
                          </a>
                        </p>

                        <div class="activity-info">
                          <div class="d-flex align-items-start flex-column">
                            <small class="mt-1">Date</small>
                            <p class="m-0 fw-bold">{{ event.start_date }} - {{ event.end_date }}</p>
                          </div>
                          <Button title="View more locations" class="btn btn-secondary-sm align-items-baseline"
                            @click="locationDailogVisible = true, handleViewLocationClick(event.EventLocations, event.name)">
                            <span class="badge bg-primary font-5 me-1">
                              <i class="fa-solid fa-location-dot me-1"></i>{{ event.locationCount }}
                            </span>
                            Location
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <!-- <tab name="Flood Statistics" id="heatmap" style="display: ;">
              <div class="col-lg-12 px-0 bg-white provinve-wise">
                <div class="provinve-wise-body">
                  <div class="d-flex flex-nowrap align-items-center justify-content-between">
                    <div class="flood-statistics">
                      Total Injured
                    </div>
                    <div class="flood-statistics-count">
                      {{ totalHeatMap }}
                    </div>
                  </div>
                  <div class="d-flex flex-nowrap align-items-center justify-content-between">
                    <div class="flood-statistics">
                      Total Casualties
                    </div>
                    <div class="flood-statistics-count">
                      {{ totalCasualties }}
                    </div>
                  </div>
                  <div class="d-flex flex-nowrap align-items-center justify-content-between">
                    <div class="flood-statistics">
                      Total Affected People
                    </div>
                    <div class="flood-statistics-count">
                      {{ totalAffectedPeople }}
                    </div>
                  </div>
                  <div class="d-flex flex-nowrap align-items-center justify-content-between">
                    <div class="flood-statistics">
                      Total Crops Affected
                    </div>
                    <div class="flood-statistics-count">
                      {{ totalCropsEffected }}
                    </div>
                  </div>
                  <div class="d-flex flex-nowrap align-items-center justify-content-between">
                    <div class="flood-statistics">
                      Total house Affected
                    </div>
                    <div class="flood-statistics-count">
                      {{ totalHouseEffected }}
                    </div>
                  </div>
                </div>
              </div>
            </tab> -->
            <!-- <tab name="Volunteered Causes" id="volunteercauses"> Volunteered Causes</tab> -->
          </tabs>
        </div>
        <div class="col-lg-5 map-section px-0 map-order">
          <div class="card state-pin-card">
            <div class="card-body p-2 stats-bg d-flex align-items-center">
              <div class="affected-area-info border-right ">
                <div class="affected-box mr-2">
                  <p class="font-14 m-0"><img class="img-fluid"
                      src="@/assets/img/active-activity-borderd.png" alt="milkar.com"> Active</p>
                </div>
              </div>
              <div class="pin-info ml-2">
                <div class="pin-box">
                  <p class="font-14 m-0"><img class="img-fluid"
                      src="@/assets/img/completed-activity-borderd.png" alt="milker.com"> Completed
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="vol-location">
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active fs-7" id="pills-home-tab" data-toggle="pill" data-target="#pills-home"
                  type="button" role="tab" aria-controls="pills-home" aria-selected="true">Activities Map</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link fs-7" id="pills-profile-tab" data-toggle="pill" data-target="#pills-profile"
                  type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Volunteers Activity
                  Map</button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              </div>
              <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"></div>
            </div>

          </div> -->
          <div v-if="loading" class="col-lg-12 px-0 d-flex flex-wrap position-relative">
            <Skeleton width="100vw" height="60vh"></Skeleton>
          </div>
          <GoogleMap v-else :api-key="apiKey" :libraries="['visualization']" style="height: 420px" :center="center"
            :zoom="zoom" :options="mapOptions" ref="map">
            <template v-if="showHeatMap == 2">
              <HeatmapLayer :options="{ data: heatmapData }" />
            </template>

            <template v-if="showHeatMap == 1">
              <MarkerCluster>
                <Marker v-for="(location, i) in locations"
                  :options="{ position: location, icon: location.is_completed == 1 ? require('@/assets/img/completed-activity-borderd.png') : require('@/assets/img/active-activity-borderd.png') }"
                  :key="i" @click="handleMarkerClick(location)">
                  <InfoWindow>
                    <div v-if="infoLoading"
                      class="col-lg-12 px-0 activity-listing-card position-relative d-flex flex-wrap">
                      <div class="d-flex align-items-start w-100">
                        <div class="col-md-auto map-modal-box">
                          <Skeleton width="110px" height="86px"></Skeleton>
                        </div>
                        <div class="col-md px-2 activity-info-text text-left d-flex flex-column row-gap-2">
                          <Skeleton width="100%" height="1.5rem"></Skeleton>
                          <Skeleton width="80%" height="1.5rem"></Skeleton>
                        </div>
                      </div>
                      <div class="d-flex align-items-start mt-3 w-100 mb-3">
                        <div class="col-lg-6 d-flex flex-column align-items-left px-1 row-gap-1">
                          <Skeleton width="30%" height="0.75rem"></Skeleton>
                          <Skeleton width="100%" height="1rem"></Skeleton>
                        </div>
                        <div class="col-lg-6 d-flex flex-column align-items-left px-1 row-gap-1">
                          <Skeleton width="30%" height="0.75rem"></Skeleton>
                          <Skeleton width="100%" height="1rem"></Skeleton>
                        </div>
                      </div>
                    </div>

                    <div v-else class="col-lg-12 px-0  position-relative d-flex flex-wrap">
                      <div class="d-flex activity-listing-card w-100 gap-2 mb-2">
                        <div class="col-md-2 px-0 map-modal-box">
                          <div v-if="this.actvityPicture" class="activity-img-block img-fluid">
                            <img :src="this.imageurl + '/' + this.actvityPicture" class="map-img-block img-fluid">
                          </div>
                          <div v-else class="activity-img-block img-fluid">
                            <img src="@/assets/img/org_placeholder.png" class="map-img-block img-fluid">
                          </div>
                        </div>
                        <div class="col-md-10 activity-info-text text-left w-min flex-auto">
                          <h4 class=" mb-1 mt-1 mt-md-0">
                            <a target="_blank" :href="baseUrl + '/activities/' + this.actrivtySlug"
                              class="text-truncate-1" v-tooltip.top="this.actvityName">
                              {{ this.actvityName }}
                            </a>
                          </h4>
                          <p class="p-0 mb-1">
                            <span class="text-truncate-2">
                              {{ this.activityType }}
                            </span>
                            by
                            <span class="text-primary text-truncate-2">
                              {{ this.activityOwner }}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div class="d-flex align-items-start w-100 mb-3">
                        <div class="col-lg-6 d-flex flex-column align-items-left px-1">
                          <small>Date</small>
                          <div class="fw-bold fs-8">{{ this.actrivtyDate }}</div>
                        </div>
                        <div class="col-lg-6 d-flex flex-column align-items-left px-1">
                          <small>Time</small>
                          <div class="fw-bold fs-8">{{ this.actrivtyTime }}</div>
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                </Marker>
              </MarkerCluster>
            </template>
            <Polygon :options="pakKML" />
          </GoogleMap>
        </div>
        <div class="col-lg-3 milkar-order-3 card banner-section provinve-wise">
          <a href="/activities" class="btn btn-primary w-100 text-center mb-2 mt-0"><i class="fa fa-search pr-1"></i>
            Search Activities
          </a>
          <div class="col-lg-12 px-0 bg-white provinve-wise">
            <h3 class="mb-0 px-3 py-2">Province-Wise Activities</h3>
            <div class="provinve-wise-body">
              <div v-if="provinceWiseEvents">
                <div class="d-flex flex-nowrap align-items-center justify-content-between"
                  v-for="(provinceEvent, index) in provinceWiseEvents" :key="index">
                  <a @click="sendProvinceId(provinceEvent.provices[0].id)" class="province-filter"
                    id="provinceEvent.province_id" href="#">{{ provinceEvent.provices[0].name
                    }}</a>
                  <div class="provinve-wise-count">
                    {{ provinceEvent.total }}
                  </div>
                </div>
              </div>
              <div v-else class="row mx-0">
                <div class="d-flex flex-nowrap align-items-center justify-content-between">
                  <Skeleton width="80%" height="43px" class="mb-2"></Skeleton>
                  <Skeleton width="18%" height="43px" class="mb-2"></Skeleton>
                </div>
                <div class="d-flex flex-nowrap align-items-center justify-content-between">
                  <Skeleton width="80%" height="43px" class="mb-2"></Skeleton>
                  <Skeleton width="18%" height="43px" class="mb-2"></Skeleton>
                </div>
                <div class="d-flex flex-nowrap align-items-center justify-content-between">
                  <Skeleton width="80%" height="43px" class="mb-2"></Skeleton>
                  <Skeleton width="18%" height="43px" class="mb-2"></Skeleton>
                </div>
                <div class="d-flex flex-nowrap align-items-center justify-content-between">
                  <Skeleton width="80%" height="43px" class="mb-2"></Skeleton>
                  <Skeleton width="18%" height="43px" class="mb-2"></Skeleton>
                </div>
                <div class="d-flex flex-nowrap align-items-center justify-content-between">
                  <Skeleton width="80%" height="43px" class="mb-2"></Skeleton>
                  <Skeleton width="18%" height="43px" class="mb-2"></Skeleton>
                </div>
                <div class="d-flex flex-nowrap align-items-center justify-content-between">
                  <Skeleton width="80%" height="43px" class="mb-2"></Skeleton>
                  <Skeleton width="18%" height="43px" class="mb-2"></Skeleton>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Dialog v-model:visible="locationDailogVisible" modal :header="this.dailogHeader" :style="{ width: '50vw' }">
        <div id="mycustommodelcontenet" class="scroll list-group">
          <div class="border-bottom pb-2 mb-2">
            <!-- <div class="alert alert-info" role="alert">
              <i class="fa fa-info-circle" aria-hidden="true"></i> By clicking the locattion url will redirect to map
              location!
            </div> -->
            <a v-for="(location, index) in eventLocationData" :key="index" target="_blank"
              class="custommlocation-icon d-flex list-group-item list-group-item-action"
              :href="'https://www.google.com/maps/search/?api=1&query=' + location.latitude + ',' + location.longitude">
              <!-- @click="panToMarker(selectedMarkerIndex)"> -->
              <img src="https://i.ibb.co/HFYVTtw/map.png" alt="milkar.com">{{ location.address }}
            </a>
          </div>
        </div>
      </Dialog>
    </div>
  </section>
</template>

<script>

import { getlocations, getheatMap, getallCauses, getProvinceActivitiesCount, getallheatMap, getHomeActivity } from "@/api";
import { Tabs, Tab } from 'vue3-tabs-component';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { GoogleMap, Marker, MarkerCluster, InfoWindow, HeatmapLayer, Polygon } from 'vue3-google-map'
import { pakistanCoordinates } from '@/./json/pakistanPolygonCoordinates.json';
import "keen-slider/keen-slider.min.css"
import KeenSlider from "keen-slider"
import { defineComponent, ref, onMounted } from "vue";
import Skeleton from 'primevue/skeleton';
import { onBeforeRouteUpdate } from 'vue-router';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';


const animation2 = { duration: 30000, easing: (t) => t }
export default {
  name: "HomeView",
  components: {
    Tabs,
    Tab,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    GoogleMap,
    Marker,
    MarkerCluster,
    InfoWindow,
    HeatmapLayer,
    Polygon,
    Skeleton,
    Dialog,
    Button,
  },
  props: {},
  computed: {
  },
  data() {
    return {
      imageurl: null,
      baseUrl: null,
      openedMarkerID: null,
      openInfoWindowIndex: null,
      center: { lat: 29.8519766, lng: 69.0696546 },
      apiKey: 'AIzaSyB0h4K6Etg1rhbX40IVsj8twaIIm8fuo3M',
      zoom: 5,
      provinceWiseEvents: null,
      allEvents: [],
      allHeatMap: [],
      totalHeatMap: 0,
      totalCasualties: 0,
      totalAffectedPeople: 0,
      totalCropsEffected: 0,
      totalHouseEffected: 0,
      heatMapinfos: [],
      allLocationsList: [],
      poularEvents: [],
      actvityPicture: null,
      actvityName: null,
      activityOwner: null,
      activityType: null,
      actrivtyDate: null,
      actrivtyTime: null,
      actrivtySlug: null,
      ownerSlug: null,
      selectedRow: null,
      dailogHeader: null,
      eventLocationData: [],
      locations: [],
      loading: true, // To show loading message\
      infoLoading: true,
      errored: false, // To show error message
      showHeatMap: 1,
      slider1: null,
      loadingAllEvents: true, // To show loading message
      mapOptions: {
        mapControls: [],
        disableDefaultUI: true, // Disable all default UI controls
        mapTypeControl: false, // Disable the map type control
        zoomControl: true, // Disable the zoom control
        streetViewControl: false, // Disable the street view control
        fullscreenControl: false, // Disable the fullscreen control
      },
      locationDailogVisible: false,
      //heat map option are here 
      eventLocationId: null,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    ...mapActions(['updateProvinceId']),
    createCauseClick() {
      localStorage.setItem('isCreateCause', true);
      if (!this.isAuthenticated) {
        window.location.href = "login";
      } else {
        if (this.user.volunteer) {
          window.location.href = "volunteer-dashboard/create-activity";
        } else {
          window.location.href = "organization-dashboard/create-activity";
        }
      }
    },
    async sendProvinceId(id) {
      await this.updateProvinceId(id);
      window.location.href = '/activities';
    },
    // panToMarker(selectedMarkerIndex, location) {
    //   this.eventLocationId = location.event_locations_id;
    //   console.log(location);
    //   this.handleMarkerClick(location);
    //   this.locationDailogVisible = false;

    // },
    loadHeatmapData() {
      axios.get('your-api-url')
        .then(response => {
          // Process the API response and set the heatmap data
          this.heatmapData = response.data;
          // Set the showHeatMap flag to 2 to display the heatmap
          this.showHeatMap = 2;
        })
        .catch(error => {
          // Handle API erroru
        });
    },
    async tabMapChanged(selectedTab) {
      if (selectedTab.tab.name == "All Causes") {
        this.showHeatMap = 1;
      }
      if (selectedTab.tab.name == "Flood Statistics") {
        this.showHeatMap = 2;
      }
    },
    handleTabActivated(sliderName) {
      // Initialize the slider when the tab becomes active
      this.initializeSlider(sliderName);
    },
    async handleMarkerClick(location) {
      const closeButton = document.querySelector('[aria-label="Close"]');

      // If the close button is found, trigger its click event
      if (closeButton) {
        closeButton.click();
      }
      // console.log(location);
      const res = await getHomeActivity(location.event_id);
      this.infoLoading = false;
      if (this.openInfoWindowIndex !== location.id) {
        this.openInfoWindowIndex = location.id;
      } else {
        this.openInfoWindowIndex = null;
      }

      this.openInfoWindowIndex = location.id ? location.id : location.event_locations_id;

      this.actvityName = res.activity ? res.activity.name : '';
      this.activityOwner = res.activity ? res.activity.OwenerDetail[0].name : '';
      this.activityType = res.activity ? res.activity.event_type.name : '';

      if (res.activity && res.activity.start_date && res.activity.end_date) {
        this.actrivtyDate = res.activity.start_date + ' - ' + res.activity.end_date;
      }
      else if (res.activity && res.activity.start_date && !res.activity.end_date) {
        this.actrivtyDate = res.activity.start_date
      }
      else {
        this.actrivtyDate = null;
      }
      if (res.activity && res.activity.formattedStartTime && res.activity.formattedEndTime) {
        this.actrivtyTime = res.activity.formattedStartTime + ' - ' + res.activity.formattedEndTime;
      }
      else if (res.activity && res.activity.formattedStartTime && !res.activity.formattedEndTime) {
        this.actrivtyTime = res.activity.formattedStartTime
      }
      else {
        this.actrivtyDate = null;
      }
      this.actrivtySlug = res.activity ? res.activity.slug : '';
      this.ownerSlug = res.activity.OwenerDetail[0]?.slug;
      this.actvityPicture = res.activity ? res.activity.thumbnail_picture : '';
      // console.log(res.activity);
      // Set the info window content for the clicked marker
    },
    handleViewLocationClick(eventLocations, name) {
      this.eventLocationData = eventLocations;
      this.dailogHeader = name;
    },
  },
  async mounted() {
    const userdata = localStorage.getItem("user");
    this.user = JSON.parse(userdata);

    this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
    this.baseUrl = window.location.origin;
    onBeforeRouteUpdate((to, from, next) => {
      // Remove the hash tag from the URL when switching tabs
      if (to.hash !== '') {
        next({ path: to.path });
      } else {
        next();
      }
    });
    try {
      /* eslint-disable */
      //this.provinceWiseEvents = response.data.provinceWiseCount;
      const locationsresponse = await getlocations();
      locationsresponse.forEach((item) => {
        this.locations.push({ id: item.id, event_id: item.event_id, lat: parseFloat(item.latitude), lng: parseFloat(item.longitude), is_completed: item.is_completed })
      });
      this.allEvents = await getallCauses();
      if (this.showHeatMap == 2) {
        this.allHeatMap = await getallheatMap();
        this.totalHeatMap = this.allHeatMap.total;
        this.totalCasualties = this.allHeatMap.total_casualties;
        this.totalAffectedPeople = this.allHeatMap.total_affected_people;
        this.totalCropsEffected = this.allHeatMap.total_crops_effected;
        this.totalHouseEffected = this.allHeatMap.total_house_effected;
      }
      this.loadingAllEvents = false;
      await this.$nextTick();
      this.slider = new KeenSlider(this.$refs.slider, {
        loop: true,
        renderMode: "performance",
        slides: {
          origin: "center",
          perView: 2,
          spacing: 10,
        },
        created(s) {
          s.moveToIdx(5, true, animation2)
        },
        updated(s) {
          s.moveToIdx(s.track.details.abs + 5, true, animation2)
        },
        animationEnded(s) {
          s.moveToIdx(s.track.details.abs + 5, true, animation2)
        },
        vertical: true,
      })
    } catch (error) {
      console.error(error);
      this.errored = true;
    } finally {
      this.loadingAllEvents = false;
    }
    try {
      this.provinceWiseEvents = await getProvinceActivitiesCount();
    } catch (error) {
      console.error(error);
      this.errored = true;
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    if (this.slider) this.slider.destroy();
  },
  setup() {
    const pakKML = {
      paths: pakistanCoordinates,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#939393",
      fillOpacity: 0.35,
    };
    const heatmapData = [];
    onMounted(async () => {
      const heatmapresponse = await getheatMap();
      heatmapresponse.forEach((item) => {
        heatmapData.push({ lat: item.latitude, lng: item.longitude })
      });
    });
    return {
      heatmapData,
      pakKML,
    }
  },
};
</script>

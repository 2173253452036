<template>
    <div class="container-xxxl px-0">
        <div class="row row-gap-3">
            <Sidebar v-model:visible="visible">
                <div class="card-body">
                    <h3 class="">Filter Options</h3>
                    <div class="d-flex flex-column align-items-start gap-2 mb-2">
                        <InputText v-model="volunteerName" type="text" placeholder="Name" class="w-100" />
                        <Dropdown v-model="selectedProvince" :options="provinves" optionLabel="name"
                            placeholder="Province" class="w-100" />
                        <Dropdown v-model="selectedCity" :options="cities" optionLabel="name" placeholder="City"
                            class="w-100" :disabled="!selectedProvince" />
                        <MultiSelect v-model="selectedSkills" display="chip" :options="skills" optionLabel="name"
                            placeholder="Select Skills" :maxSelectedLabels="100" class="w-100" />
                        <MultiSelect v-model="selectedEducation" display="chip" :options="education" optionLabel="name"
                            placeholder="Select Causes" :maxSelectedLabels="100" class="w-100" />
                    </div>
                    <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                        @click="searchVolunteers" />
                    <Button type="button" class="btn btn-secondary w-100" label="Reset Filter" icon="pi pi-times"
                        @click="resetFilters" />
                </div>
            </Sidebar>
            <div class="d-flex flex-column align-items-start gap-2">
                <DataView :value="volunteers" :layout="layout">
                    <template #header>
                        <div class="row justify-content-between flex-wrap row-gap-2 p-buttonset">
                            <div
                                class="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-sm-start justify-content-center flex-wrap">
                                <h3 class="mb-0 me-3">Explore Volunteers</h3>
                                <Chip class="pl-0 pr-3">
                                    <span class="ml-2 font-medium small">
                                        <b>{{ volunteersCount }}</b>
                                        Found
                                    </span>
                                </Chip>
                            </div>
                            <div
                                class="col-lg-7 col-md-7 col-sm-12 d-flex align-content-center gap-2 justify-content-end flex-wrap">
                                <div v-if="this.volunteersCount > 0" class="d-flex justify-content-end">
                                    <a class="btn btn-primary btn-xs" @click="acceptAllRequest(4, this.slug)">Send Invite To All</a>
                                    <a class="btn btn-secondary btn-xs ml-1" @click="acceptAllRequest(3, this.slug)">Ignore All</a>
                                </div>
                                <div
                                    class="d-flex align-items-center justify-content-sm-end justify-content-center flex-grow-1 d-none">
                                    <Button type="button" class="btn btn-primary-sm" label="Mark Completed All" />
                                </div>
                                <div
                                    class="d-flex align-items-center justify-content-center gap-2 flex-grow-1 flex-sm-nowrap flex-wrap">
                                    <div class="p-float-label sortable-select w-100">
                                        <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label"
                                            placeholder="Sort Volunteers" @change="onSortChange($event)"
                                            class="w-100" />
                                    </div>
                                    <DataViewLayoutOptions class="d-flex" v-model="layout" />
                                    <Button class="filter-opt" icon="pi pi-filter-fill" @click="visible = true" />
                                </div>
                            </div>
                        </div>

                    </template>

                    <template #list="slotProps">
                        <div v-for="(event, index) in slotProps.items" :key="index">
                            <div v-if="loading" class="row w-100 mx-0 p-4" style="z-index: 99;">
                                <div class="col-lg-2 px-0">
                                    <Skeleton class="w-8rem border-round h-9rem" />
                                </div>
                                <div class="col-lg-7 px-0 gap-1">
                                    <Skeleton class="w-8rem border-round h-2rem my-1" />
                                    <div class="row mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem" />
                                        <Skeleton class="w-8rem border-round h-2rem" />
                                        <Skeleton class="w-4rem border-round h-2rem" />
                                    </div>
                                    <div class="row flex-column mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                        <Skeleton class="w-20rem border-round h-2rem" />
                                    </div>
                                </div>
                                <div class="col-lg-3 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                                <Divider style="margin: 0.5rem 0 0 0 !important;"></Divider>
                            </div>
                            <div v-else class="card-body row mx-0 row-gap-3 pb-0">
                                <div class="col-lg-2 col-md-12 col-sm-12 px-0">
                                    <img v-if="!event.profile_picture_thumb" :src="this.$volunteerNoImage(event.gender)"
                                        class="activity-img-block border-radius img-fluid" />
                                    <Image v-else class="border-radius img-fluid"
                                        :src="this.imageurl + '/' + event.profile_picture_thumb" :alt="event.user?.name"
                                        preview />
                                </div>
                                <div class="col-lg-7 col-md-7 col-sm-12 px-lg-3 px-0 py-0 text-left text-truncate gy-2">
                                    <a :href="baseUrl + '/volunteers/' + event.slug">
                                        <h3 class="mb-1 mt-1 mt-sm-0 text-truncate-1">
                                            {{ event.user?.name }}
                                        </h3>
                                    </a>
                                    <p class="mb-2 d-flex flex-wrap fs-7 gap-2 row-gap-2">
                                        <span v-if="event.gender" class="badge-custom d-xl-inline-block">
                                            {{ this.$getFormattedGender(event.gender) }}
                                        </span>
                                        <span v-if="event.education" class="badge-custom d-xl-inline-block">
                                            {{ event.education?.name }}
                                        </span>
                                        <span v-if="event.education_field" class="badge-custom d-xl-inline-block">
                                            {{ event.education_field?.name }}
                                        </span>
                                        <span v-if="event.university" class="badge-custom d-xl-inline-block">
                                            {{ event.university?.name }}
                                        </span>
                                    </p>
                                    <p v-if="event.city?.name" class="my-1 fs-7">
                                        <strong>City:</strong> {{ event.city?.name }}
                                    </p>
                                    <p v-else class="my-1 fs-7">
                                        <strong>City:</strong> N/A
                                    </p>
                                    <p v-if="event.about" class="text-truncate-2 w-fit" v-tooltip.bottom="event.about">
                                        {{ event.about }}
                                    </p>
                                </div>
                                <div
                                    class="col-lg-3 col-md-12 col-sm-12 card-section-right d-flex align-items-center justify-content-center flex-wrap card">
                                    <div class="row mx-0 flex-wrap w-100 justify-content-center row-gap-2">
                                        <div class="badge-counter-box d-flex flex-column row-gap-2 mb-0 px-0">
                                            <span class="badge-counter-text">
                                                Activities Volunteered:
                                                <span v-if="event.totalActivities > 0" class="counter">
                                                    {{ event.totalActivities }}
                                                </span>
                                                <span v-else class="counter fs-7 text-muted"
                                                    v-tooltip.bottom="'Not Added'">
                                                    N/A
                                                </span>
                                            </span>
                                            <span class="badge-counter-text">
                                                Hours Completed:
                                                <span v-if="event.totalHours > 0" class="counter">
                                                    {{ event.totalHours }}
                                                </span>
                                                <span v-else class="counter fs-7 text-muted"
                                                    v-tooltip.bottom="'Not Added'">
                                                    N/A
                                                </span>
                                            </span>
                                        </div>
                                        <div class="ratinng-box d-flex justify-content-center row-gap-2">
                                            <div v-if="event.ratings.avgRating > 0"
                                                class="d-flex align-items-start justify-content-center gap-2 flex-nowrap">
                                                <div
                                                    class="d-flex gap-1 align-items-center text-primary fs-8 flex-nowrap w-100">
                                                    <span v-for="v in Math.floor(event.ratings.avgRating)" :key="v"
                                                        class="fas fa-star filled"></span>
                                                    <span v-if="event.ratings.halfRating % 1 !== 0"
                                                        class="fas fa-star-half-alt filled"></span>
                                                    <span v-if="event.ratings.avgRating < 5"
                                                        class="d-flex align-items-center gap-1"><span
                                                            v-for="v in Math.ceil(event.ratings.zeroRating)"
                                                            :key="'empty-' + v" class="fa-regular fa-star"></span>
                                                    </span>
                                                </div>
                                                <span class="text-secondary w-100 fs-9">
                                                    ({{ calculateRatingOutOf5(event.ratings.avgRating,
                event.ratings.fullRating) }} out of 5.0)
                                                </span>
                                            </div>
                                            <div v-else
                                                class="d-flex align-items-start justify-content-center gap-2 flex-nowrap">
                                                <div class="d-flex gap-1 align-items-start text-muted opacity-50 fs-8">
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                </div>
                                                <span class="text-muted opacity-50 fs-9"> (0 out of 5.0)
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center flex-flow-1 gap-2 px-0">
                                            <a class="btn btn-primary-sm mx-0 flex-auto"
                                                @click="updateEvent(event.id, this.slug, 4)">Send Invite
                                                <span class="promise-btn__spinner-wrapper">
                                                    <span class="spinner"
                                                        style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s;"></span>
                                                </span>
                                            </a>
                                            <a @click="updateEvent(event.id, this.slug, 3)"
                                                class="btn btn-secondary-sm flex-auto">
                                                Ignore
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <Divider class="mb-0"></Divider>
                            </div>
                        </div>
                    </template>

                    <template #grid="slotProps">
                        <div class="grid grid-nogutter">
                            <div v-for="(event, index) in slotProps.items" :key="index"
                                class="sm:col-12 lg:col-3 xl:col-3 p-2">
                                <div
                                    class="p-3 border-1 surface-border surface-card border-round position-relative overflow-hidden flex-wrap">
                                    <div v-if="loading" class="row mx-0 row-gap-1" style="z-index: 99;">
                                        <div class="col-lg-4 px-1">
                                            <Skeleton class="w-100 border-round h-100" />
                                        </div>
                                        <div class="col-lg-8 px-1 d-flex flex-column row-gap-1">
                                            <Skeleton class="w-100 border-round h-2rem" />
                                            <Skeleton class="w-100 border-round h-2rem" />
                                        </div>
                                        <div class="col-lg-12 px-1 d-flex flex-column row-gap-1">
                                            <Skeleton class="w-100 border-round h-3rem" />
                                            <Skeleton class="w-100 border-round h-2rem" />
                                        </div>
                                    </div>
                                    <div v-else class="row mx-0 align-items-start flex-wrap row-gap-2">
                                        <div class="col-lg-4 px-0">
                                            <img v-if="!event.profile_picture_thumb"
                                                :src="this.$volunteerNoImage(event.gender)"
                                                class="activity-img-block border-radius img-fluid" />
                                            <Image v-else class="border-radius img-fluid"
                                                :src="this.imageurl + '/' + event.profile_picture_thumb"
                                                :alt="event.user?.name" preview />
                                        </div>
                                        <div class="col-lg-8 text-left px-0 px-sm-0 px-md-0 px-lg-2">
                                            <a :href="baseUrl + '/volunteers/' + event.slug">
                                                <h3 title=""
                                                    class="mb-0 mt-0 text-truncate-2 fw-bold text-capitalize fs-7"
                                                    v-tooltip.top="event.user?.name">
                                                    {{ event.user?.name }}
                                                </h3>
                                            </a>
                                            <div class="col-lg-12 d-flex align-items-top flex-wrap text-left px-0 fs-8">
                                                <p v-if="event.city?.name" class="my-1 fs-8">
                                                    <strong>City:</strong> {{ event.city?.name }}
                                                </p>
                                                <p v-else class="my-1 fs-8">
                                                    <strong>City:</strong> N/A
                                                </p>
                                            </div>

                                        </div>

                                        <div
                                            class="col-lg-12 col-md-12 col-sm-12 card-section-right d-flex align-items-center justify-content-center flex-wrap card">
                                            <div class="row mx-0 flex-wrap w-100 justify-content-center row-gap-2">
                                                <div class="badge-counter-box d-flex flex-column row-gap-2 px-0 mb-0">
                                                    <span class="badge-counter-text fs-8">
                                                        Activities Volunteered:
                                                        <span v-if="event.totalActivities > 0" class="counter fs-7">
                                                            {{ event.totalActivities }}
                                                        </span>
                                                        <span v-else class="counter fs-7 text-muted"
                                                            v-tooltip.bottom="'Not Added'">
                                                            N/A
                                                        </span>
                                                    </span>

                                                    <span class="badge-counter-text fs-8">
                                                        Hours Completed:
                                                        <span v-if="event.totalHours > 0" class="counter fs-7">
                                                            {{ event.totalHours }}
                                                        </span>
                                                        <span v-else class="counter fs-7 text-muted"
                                                            v-tooltip.bottom="'Not Added'">
                                                            N/A
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="ratinng-box d-flex justify-content-center flex-auto">
                                                    <div v-if="event.ratings.fullRating || event.ratings.halfRating"
                                                        class="d-flex align-items-center text-primary gap-2 flex-wrap">
                                                        <div
                                                            class="d-flex gap-1 justify-content-center text-primary fs-8">
                                                            <i v-for="(rating, index) in event.ratings.fullRating"
                                                                class="fas fa-star rating" :key="index"></i>
                                                            <i v-if="event.ratings.halfRating"
                                                                class="fas fa-star-half-alt rating"></i>
                                                            <i v-for="(rating, index) in event.ratings.zeroRating"
                                                                class="fas fa-star rating" :key="index"></i>
                                                        </div>
                                                        <span class="text-secondary fs-9">
                                                            ({{ (event.ratings.fullRating +
                event.ratings.halfRating).toFixed(1) }} out of 5.0)
                                                        </span>
                                                    </div>
                                                    <div v-else
                                                        class="d-flex align-items-center justify-content-center flex-grow-1 gap-2 flex-wrap">
                                                        <div
                                                            class="d-flex gap-1 align-items-start text-muted opacity-50 fs-8">
                                                            <span class="fa-regular fa-star"></span>
                                                            <span class="fa-regular fa-star"></span>
                                                            <span class="fa-regular fa-star"></span>
                                                            <span class="fa-regular fa-star"></span>
                                                            <span class="fa-regular fa-star"></span>
                                                        </div>
                                                        <span class="text-muted opacity-50 fs-9"> (0 out of 5.0)
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center flex-flow-1 gap-2 px-0">
                                                    <a class="btn btn-primary-sm mx-0 flex-auto"
                                                        @click="updateEvent(event.id, this.slug, 4)">Send Invite
                                                        <span class="promise-btn__spinner-wrapper">
                                                            <span class="spinner"
                                                                style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s;"></span>
                                                        </span>
                                                    </a>
                                                    <a @click="updateEvent(event.id, this.slug, 3)"
                                                        class="btn btn-secondary-sm flex-auto">
                                                        Ignore
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #footer v-if="hasMoreVolunteers">
                        <div class="p-4">
                            <div v-if="hasMoreVolunteers" class="text-center">
                                <button @click="loadMoreVolunteers" class="btn btn-primary" :disabled="showSpinner">
                                    View More
                                    <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px"
                                        strokeWidth="8" fill="transparent" animationDuration="2s"
                                        aria-label="Custom ProgressSpinner" />
                                </button>
                            </div>
                        </div>
                    </template>
                    <template #empty v-if="!loading">
                        <div class="text-center">
                            <img src="@/assets/img/no-data-image.png" alt="No Results Found" class="w-50" />
                        </div>
                    </template>
                </DataView>
            </div>
        </div>
    </div>
</template>

<script>
import { getEventDashboardVolunteersRecommended, getVolunteersFilters } from "@/api";
import Image from 'primevue/image';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Badge from 'primevue/badge';
import Sidebar from 'primevue/sidebar';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Chip from 'primevue/chip';
import Skeleton from 'primevue/skeleton';
import ProgressSpinner from 'primevue/progressspinner';


export default {
    components: {
        Image,
        Button,
        Divider,
        InputText,
        Calendar,
        Dropdown,
        MultiSelect,
        Badge,
        Sidebar,
        DataView,
        DataViewLayoutOptions,
        Chip,
        Skeleton,
        ProgressSpinner,
    },
    data() {
        return {
            loading: true,
            pageLoading: true,
            selectedProvince: null,
            selectedCity: null,
            selectedEventTypes: [],
            selectedSkills: [],
            selectedCities: [],
            selectedEducation: [],
            volunteers: [],
            cities: [],
            provinves: [],
            skills: [],
            education: [],
            volunteersCount: 0,
            pageNo: 1,
            perPage: 12,
            sortKey: '',
            sortvalue: null,
            sortType: null,
            imageurl: null,
            volunteerName: null,
            visible: false,
            layout: 'list',
            sortOptions: [
                { label: 'Name ASC', value: 'name', type: 'asc' },
                { label: 'Name DESC', value: 'name', type: 'desc' },
            ],
            layoutOptions: [
                { name: 'List', icon: 'pi pi-bars' },
                { name: 'Grid', icon: 'pi pi-th-large' }
            ],
            home: {
                icon: 'pi pi-home',
                route: '/'
            },
            items: [
                { label: 'Volunteer dashboard' },
                { label: 'All volunteers' },
            ],
            showSpinner: false,
        }

    },
    computed: {
        hasMoreVolunteers() {
            return this.volunteersCount > this.perPage * this.pageNo;
        },
    },
    props: ['slug'],
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            this.loading = true;
            this.pageLoading = true;
            const params = {
                slug: this.slug,
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const responce = await getEventDashboardVolunteersRecommended(params);
            // console.log(responce);
            this.volunteers = responce.volunteers.data;
            this.volunteersCount = responce.volunteersCount;
            const res2 = await getVolunteersFilters();
            this.causes = res2.causes;
            this.allCities = res2.cities;
            this.eventTypes = res2.eventTypes;
            this.provinves = res2.provinves;
            this.skills = res2.skills;
            this.education = res2.Education;
            this.loading = false;
            this.pageLoading = false;
        } catch (error) {
            console.error(error);
        }
    },
    watch: {
        selectedProvince(newProvince) {
            // Filter cities based on the selected province and update the cities options
            if (newProvince) {
                this.cities = this.allCities.filter((city) => city.province_id === newProvince.id);
            } else {
                // If no province is selected, reset the cities options to empty
                this.cities = [];
            }
        },
    },
    methods: {
        async searchVolunteers() {
            try {
                this.loading = true;
                const params = {
                    slug: this.slug,
                    pageno: 1, // Reset the page number to 1 for a new search
                    perpage: this.perPage,
                    name: this.volunteerName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    skill_ids: this.selectedSkills.map((skill) => skill.id),
                    education_id: this.selectedEducation ? this.selectedEducation.id : null,
                };
                const res = await getEventDashboardVolunteersRecommended(params);
                this.volunteers = res.volunteers.data;

                this.volunteersCount = res.volunteersCount;
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },
        async loadMoreVolunteers() {
            this.showSpinner = true;
            try {
                this.loading = true;
                const params = {
                    slug: this.slug,
                    pageno: this.pageNo + 1,  // Reset the page number to 1 for a new search
                    perpage: this.perPage,
                    name: this.volunteerName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    skill_ids: this.selectedSkills.map((skill) => skill.id),
                    education_id: this.selectedEducation ? this.selectedEducation.id : null,
                    sortBy: this.sortvalue,
                    sortType: this.sortType,
                };

                const res = await getEventDashboardVolunteersRecommended(params);
                const newVolunteers = res.volunteers.data;
                this.volunteers = [...this.volunteers, ...newVolunteers];
                this.pageNo++; // Update the current page number
                this.loading = false;
                this.showSpinner = false;
            } catch (error) {
                console.error(error);
            }
        },
        resetFilters() {
            this.loading = true;
            this.volunteerName = '';
            this.selectedProvince = null;
            this.selectedCity = null;
            this.selectedSkills = [];
            this.selectedEducation = [];
            this.searchVolunteers();
            this.loading = false;
        },
        async onSortChange(event) {
            this.loading = true;
            this.sortvalue = event.value.value;
            this.sortType = event.value.type;
            const params = {
                slug: this.slug,
                pageno: this.pageNo,
                perpage: this.perPage,
                sortBy: this.sortvalue,
                sortType: this.sortType,
            };

            const res = await getEventDashboardVolunteersRecommended(params);
            this.volunteers = res.volunteers.data;
            this.volunteersCount = res.volunteersCount;
            this.loading = false;
        },
        updateEvent(id, slug, status) {
            this.$updateEventStatus(id, slug, status).then(result => {
                this.result = result;
                if (this.result) {
                    if (this.result && status == 4) {
                        this.$swal.fire({
                            title: 'Great!',
                            text: 'You have successfully sent a invitation to this volunteer.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            showConfirmButton: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.searchVolunteers();
                            }
                        });
                    }
                    if (this.result && status == 3) {
                        this.$swal.fire({
                            title: 'Success!',
                            text: "You have ignored this recommended volunteer!",
                            icon: 'success',
                            confirmButtonText: 'OK',
                            confirmButtonColor: "#46B849",
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.searchVolunteers();
                            }
                        });
                    }
                }
                else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }
            });

        },
        acceptAllRequest(status){
            const slug= this.slug;
            this.$updateVolunteerAll(slug, status).then(result => {
                this.result = result;
                if(this.result) {
                    if (this.result[0] == 4) {
                        this.$swal.fire({
                            title: 'Great!',
                            text: 'You have successfully sent invitation to all volunteers.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            confirmButtonColor: "#46B849",
                            allowOutsideClick: false,
                        });
                        this.searchVolunteers();
                    }
                    if (this.result[0] == 3) {
                        this.$swal.fire({
                            title: 'Success!',
                            text: "You have ignored all recommended volunteers!",
                            icon: 'success',
                            confirmButtonText: 'OK',
                            confirmButtonColor: "#46B849",
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.searchVolunteers();
                            }
                        });
                    }
                }
                else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }

            });
        },
        calculateRatingOutOf5(avgRating, fullRating) {
            return Math.round(avgRating * 10) / 10;
        },
    },
}
</script>
<template>
    <simpleListingSkeleton v-if="pageLoading"></simpleListingSkeleton>
    <div v-if="institutes.length > 0">

        <div class="card-body row mx-0 border-bottom" v-for="(institute, index) in institutes" :key="index">
            <div class="col-lg-2 col-md-12 text-center">
                <img v-if="!institute.logo_thumb" src="@/assets/img/org_placeholder.png" class="border-radius img-fluid" />
                <Image v-else class="border-radius img-fluid" preview :src="this.imageurl + '/' + institute.logo_thumb"
                    :alt="institute.user.name" />
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 text-truncate fs-7 px-3 mb-2 text-left">
                <a :href="baseUrl + '/organizations/' + institute.slug">
                    <h3 v-tooltip.top="institute.name" class="mb-2 mt-1 text-truncate-1 fw-bold">
                        {{ institute.name }}
                    </h3>
                </a>
                <div class="m-0 d-flex flex-column align-items-start row-gap-1">
                    <span class="d-flex flex-column">
                        <label class="fs-8">Phone</label>
                        <span v-if="institute.contact_person_phone" class="fw-bold">
                            {{ institute.contact_person_phone }}
                        </span>
                        <span v-else class="fw-bold" v-tooltip.bottom="'Image Not Added'">
                            N/A
                        </span>
                    </span>
                    <span class="d-flex flex-column">
                        <label class="fs-8">Email</label>
                        <span v-if="institute.contact_person_email" class="fw-bold">
                            {{ institute.contact_person_email }}
                        </span>
                        <span v-else class="fw-bold" v-tooltip.bottom="'Image Not Added'">
                            N/A
                        </span>
                    </span>
                    <span class="d-flex flex-column">
                        <label class="fs-8">Address</label>
                        <span v-if="institute.address" class="fw-bold text-truncate-1" v-tooltip.bottom="institute.address">
                            {{ institute.address }}
                        </span>
                        <span v-else class="fw-bold" v-tooltip.bottom="'Image Not Added'">
                            N/A
                        </span>
                    </span>
                </div>
            </div>
            <div
                class="col-lg-3 col-md-12 col-sm-12 card-section-right d-flex align-items-center justify-content-center flex-wrap card">
                <div class="row mx-0 flex-wrap w-100 justify-content-center">
                    <a title="View Profile" :href="baseUrl + '/institutes/' + institute.slug"
                        class="btn btn-primary w-100 px-1">
                        <i class="fa-solid fa-eye pr-1"></i>View Profile
                    </a>
                </div>
            </div>
        </div>
        <div class="p-4">
            <div v-if="hasMoreInstitutes" class="text-center">
                <button @click="loadMore" class="btn btn-primary" :disabled="showSpinner">
                    View More
                    <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8" fill="transparent"
                        animationDuration="2s" aria-label="Custom ProgressSpinner" />
                </button>
            </div>
        </div>
    </div>
    <div class="text-center no-data-image" v-else>
        <img src="@/assets/img/no-data-image.png" alt="No Results Found" class="w-50" />
    </div>
</template>

<script>
import { getUniversities } from "@/api";
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import 'primeflex/primeflex.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import Button from 'primevue/button';
import Image from 'primevue/image';
import Tag from 'primevue/tag';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import Card from 'primevue/card';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import simpleListingSkeleton from "@/components/Skeletons/simpleListingSkeleton";


export default {
    name: "Institutes",
    components: {
        DataView,
        DataViewLayoutOptions,
        Button,
        Image,
        Tag,
        Chip,
        Divider,
        Card,
        Sidebar,
        Skeleton,
        Dialog,
        InputText,
        ProgressSpinner,
        simpleListingSkeleton,

    },
    data() {
        return {
            loading: false,
            pageLoading: true,
            pageNo: 1,
            perPage: 12,
            imageurl: null,
            OrganizationName: null,
            baseUrl: null,
            selectedProvince: null,
            selectedOrganizationType: null,
            selectedCity: null,
            institutes: [],
            organizationTypes: [],
            cities: [],
            provinces: [],
            instituteCount: 0,
            layout: 'list',
            visible: false,
            layoutOptions: [
                { name: 'List', icon: 'pi pi-bars' },
                { name: 'Grid', icon: 'pi pi-th-large' }
            ],
            showSpinner: false,
        };
    },
    computed: {
        hasMoreInstitutes() {
            return this.instituteCount > this.perPage * this.pageNo;
        },
    },
    props: {
        filteredData: Object
    },
    watch: {
        filteredData: {
            immediate: true, // Run the watch handler immediately on component mount
            deep: true, // Watch nested properties of filteredData
            handler(newData) {
                if (newData) {
                    this.searchInstitutes(newData); // Trigger API call when filteredData changes
                }
            },
        },
    },
    async mounted() {
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        this.baseUrl = window.location.origin;
        try {
            //this.loading = true;
            this.pageLoading = true;
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const res = await getUniversities(params);
            this.institutes = res.universities.data;
            this.instituteCount = res.organizationsCount;
            this.loading = false;
            this.pageLoading = false;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        async searchInstitutes(data) {
            try {
                this.pageLoading = true;
                const params = {
                    pageno: this.pageNo,
                    perpage: this.perPage,
                    name: data.name,
                    address: data.address,
                };
                const res = await getUniversities(params);
                this.institutes = res.universities.data;
                this.instituteCount = res.institutesCount;
                this.loading = false;
                this.pageLoading = false;
            } catch (error) {
                console.error(error);
            }
        },
        resetFilters() {
            this.loading = true;
            this.searchInstitutes();
            this.loading = false;
        },
        async loadMore(data) {
            this.showSpinner = true;
            try {
                this.loading = true;
                const params = {
                    pageno: this.pageNo,
                    perpage: this.perPage,
                    name: data.name,
                    address: data.address,
                };
                const res = await getUniversities(params);
                const newInstitutes = res.universities.data;
                this.institutes = [...this.institutes, ...newInstitutes];
                this.pageNo++;
                this.loading = false;
                this.showSpinner = false;
            } catch (error) {
                console.error(error);
            }
        },
    },
}
</script>

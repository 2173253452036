<template>
    <section class="container-fluid organization-section">
        <div class="row mx-0 px-0">
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                <div class="col-lg-10 organization-content">
                    <h2 class="tittle mb-3">Pakistan’s Top Organizations on a Single Platform</h2>
                    <p class="">Organizations get the opportunity to expand their volunteer network and carry out organized
                        activities effectively.
                        We offer an excellent recommendation model based on volunteers’ areas of interest and demographics.
                    </p>
                    <a :href="baseUrl + '/organizations' " class="btn btn-primary mb-2"><i class="fa-solid fa-eye pr-1"></i> View All</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="customer-logos">
                    <div ref="slider" class="keen-slider">
                        <div v-for="(organization, index) in organizations" :key="index" class="keen-slider__slide"
                            :class="'number-slide' + (index + 1)">
                            <a :href="baseUrl + '/organizations/'+ organization.slug ">
                                <img class="img-fluid customer-logo-img" :alt="organization.user.name"
                                    :src="organization.logoThumbnailUrl">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { getOrganizations } from "@/api";
import "keen-slider/keen-slider.min.css"
import KeenSlider from "keen-slider"

const animation = { duration: 10000, easing: (t) => t }
export default {
    name: "HomeView",
    components: {
        KeenSlider,
    },
    props: {},
    computed: {},
    data() {
        return {
            imageurl: null,
            organizations: [],
            loadingorganizations: true,
            errored: false, // To show error message
            slider: null,
            baseUrl: null,
            //heat map option are here 
        };
    },
    methods: {

    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            /* eslint-disable */

            this.organizations = await getOrganizations();
            this.loadingHomeslider = false;
            // console.log(this.organizations);
            await this.$nextTick();
            this.slider = new KeenSlider(this.$refs.slider, {
                loop: true,
                renderMode: "performance",
                drag: true,
                slides: {
                    perView: 4,
                    spacing: 10,
                },
                created(s) {
                    s.moveToIdx(3, true, animation)
                },
                updated(s) {
                    s.moveToIdx(s.track.details.abs + 5, true, animation)
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 5, true, animation)
                },
            });
        } catch (error) {
            console.error(error);
            this.errored = true;
        } finally {
            this.loading = false;
        }
    },
    beforeDestroy() {
        if (this.slider) this.slider.destroy();
    },
};
</script>
  